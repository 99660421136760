import React, { useState } from 'react';
import Recaptcha from 'react-recaptcha';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { mq } from '../components/_shared/media';
import { blogMenuLinks } from '../components/_config/menu-links';
import { StyledH1 } from '../components/_shared/styled-headings';
import { StyledFullHeightSection } from '../components/_shared/styled-section';
import Icon from '../components/icon'


const Form = styled.form`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

`

const GridChild = styled.div` 
  width: 100%;
  display:flex;
  flex-direction: column;
`

const SmallGrid = styled.div`
  display:flex;
  flex-direction: row;
  gap: 1rem;
`

const RadioContainer = styled.div`
  display: flex;

  label {
    display: flex;
    align-items: center;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .radioReplacement {
      background: var(--primary-color);
      /* background: radial-gradient(var(--primary-color) 50%, rgba(255, 0, 0, 0) 51%); */
    }
  }

  .radioReplacement {
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.15em solid var(--primary-color);
    margin-right: 0.5rem;

  }
`

const Radios = styled.div` 
  display: flex;
  gap: 1rem;
`

const Input = styled.input`  
  background-color: var(--bg-content-color);
  border: none;
  height: 4ch;
  color: white;
  padding: 0.5rem;
  width: 100%;
`

// const SmallInput = styled.input` 
//   background-color: var(--bg-content-color);
//   border: none;
//   height: 4ch;
//   color: white;
//   padding: 0.5rem;
//   width: 50%;

// `

const TextArea = styled.textarea` 
  background-color: var(--bg-content-color);
  border: none;
  padding: 0.5rem;
  color: white;
  width: 100%;
`

const SubText = styled.div`
  color: var(--disabled-color);
  font-size: 0.7em;
`

const SocialMediaInputs = styled.div`
  display: grid;

  ${mq.gt.sm} {
    grid-template-columns: 1fr 1fr;
  }
  grid-template-columns: 1fr;

  gap: 0.5rem;

  div {
    display: flex;
    gap: 0.5rem;
  }

  ${mq.gt.sm} {
    .customLinks {
      grid-column: 1 / span 2;
    }
  }
`

const Submit = styled.button` 
  margin-top: 1rem;
  background: transparent;
  border: solid 0.15em var(--primary-color);
  color: var(--primary-color);
  font-size: 1.5rem;
`

const Warning = styled(SubText)`
  color: var(--warning-color);
`;

const Select = styled.select`
  background-color: var(--bg-content-color);
  border: none;
  height: 6ch;
  color: white;
  padding: 0.5rem;
  width: 100%;
`


const Index = () => {

  const [existingDomain, setExistingDomain] = useState(0);

  const ExistingDomain = () => {

    if (existingDomain == 1) {
      return (
        <>
          <label htmlFor="existingDomain">What is the domain?</label>
          <Input type="text" id="existingDomain" name="existingDomain" required/>
        </>
      )          
    } else if (existingDomain == 2) {
      return (
        <>
          <label htmlFor="newDomain">What domain would you like?</label>
          <SubText>Feel free to list any number of domains in order of preference in case one or more are already taken.</SubText>
          <Warning>A fresh domain name will cost extra.</Warning>
          <TextArea type="text" id="newDomain" name="newDomain" rows="6" required/>
        </>
      )
    } else {
      return <></>
    }
  }

  const [email, setEmail] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    console.log(`Email ${email}`)
  }
 
  return (
    <Layout menuLinks={blogMenuLinks}>
    <SEO title="Questionnaire" />

      <StyledFullHeightSection>

        <StyledH1>Thank you for allowing us to help build your business!</StyledH1>

        <span>Please fill out this small form to help us officially prepare this business relationship.</span>

        <Form name="Company Questionnaire" method="POST" data-netlify="true" action="/thanks">
          <input type="hidden" name="form-name" value="Company Questionnaire" />

          <GridChild>
            <label htmlFor="companyName">Company Name</label>
            <Input type="text" id="companyName" name="companyName" required/>
          </GridChild>

          <GridChild>
            <label htmlFor="companyAddress">Company Address</label>
            <Input type="text" id="companyAddress" name="companyAddress" required/>
          </GridChild>

          <GridChild>
            <label htmlFor="name">Your Name</label>
            <Input type="text" id="name" name="name" required/>
          </GridChild>

          <SmallGrid>
            <GridChild>
              <label htmlFor="phoneNumber">Phone Number</label>
              <Input type="number" id="phoneNumber" name="phoneNumber" required/>
            </GridChild>

            <GridChild>
              <label htmlFor="email">Email</label>
              <Input type="text" id="email" name="email" value={email} onChange={e => setEmail(e.target.value)} required/>
            </GridChild>
          </SmallGrid>

          <GridChild>
            <span>Subscription term</span>
            <Radios>
              <RadioContainer>
                <label htmlFor="monthly">
                  <input type="radio" id="monthly" name="subscription" value="monthly" required/>
                  <span className="radioReplacement"></span>
                  <span>
                    Monthly
                    <SubText>
                      $249/mo
                    </SubText>
                  </span>
                </label>
              </RadioContainer>
              <RadioContainer>
                <label htmlFor="bimonthly">
                  <input type="radio" id="bimonthly" name="subscription" value="bimonthly"/>
                  <span className="radioReplacement"></span>
                  <span>
                    6 months
                    <SubText>
                      $229/mo
                    </SubText>
                  </span>
                </label>
              </RadioContainer>
              <RadioContainer>
                <label htmlFor="annual">
                  <input type="radio" id="annual" name="subscription" value="annual"/>
                  <span className="radioReplacement"></span>
                  <span>
                    12 months
                    <SubText>
                      $199/mo
                    </SubText>
                  </span>

                  
                </label>        
              </RadioContainer>
            </Radios>
          </GridChild>

          <GridChild>
            <label htmlFor="businessType">Type of business</label>
            <SubText>Pizza shop, mechanic, painter, etc</SubText>

            <Input type="text" id="businessType" name="businessType" required/>
          </GridChild>

          <GridChild>
            <label htmlFor="objectives">What is the main purpose of your site?</label>
            <SubText>Will influence what &quot;call to action&quot; to use.</SubText>

            <Select name="objective" id="objective">
              <option value="call">Get customers to call</option>
              <option value="visit">Get customers to visit</option>
              <option value="show_product">Show customers a product/service</option>
              {/* <option value="audi">Audi</option> */}
            </Select>
          </GridChild>

          <GridChild>
            
            <span>Do you have an existing website/domain name that you would like to use?</span>
            <Radios>
              <RadioContainer>
                <label htmlFor="domainTrue">
                  <input onClick={() => setExistingDomain(1)} type="radio" id="domainTrue" name="domain" value="true" required/>
                  <span className="radioReplacement"></span>Yes
                </label>
              </RadioContainer>
              <RadioContainer>
                <label htmlFor="domainFalse">
                  <input onClick={() => setExistingDomain(2)} type="radio" id="domainFalse" name="domain" value="false"/>
                  <span className="radioReplacement"></span>No
                </label>
              </RadioContainer>
            </Radios>
            <ExistingDomain />
          </GridChild>

          <GridChild>
            <span>Do you have any existing ideas for the website&apos;s design?</span>
            <SubText>Perhaps inspiration from an existing website you like or just whatever you have in mind</SubText>
            <TextArea id="designTextarea" name="designTextarea" rows="7"></TextArea>
          </GridChild>

          <GridChild>
            <span>Do you have any content you would like to use on your website?</span>
            <SubText>You can also email this content to <a href="mailto:admin@isaacday.design">admin@isaacday.design</a> if you prefer.<br/>Content will also be gathered by the developer from your social links, stock photos, existing website, etc</SubText>
            <TextArea id="contentTextarea" name="contentTextarea" rows="7"></TextArea>

          </GridChild>

          <GridChild>
            <span>Links</span>

            <SocialMediaInputs>
              <div>
                <Icon icon="facebook" prefix="fab" />
                <label htmlFor="facebook">Facebook</label>
                <Input type="text" id="facebook" name="facebook"/>
              </div>

              <div>
                <Icon icon="instagram" prefix="fab" />
                <label htmlFor="instagram">Instagram</label>
                <Input type="text" id="instagram" name="instagram"/>
              </div>

              <div>
                <Icon icon="snapchat-ghost" prefix="fab" />
                <label htmlFor="snapchat">Snapchat</label>
                <Input type="text" id="snapchat" name="snapchat"/>
              </div>

              <div className="customLinks">
                <Icon icon="share-alt" prefix="fas" />
                <label htmlFor="custom">Other Links</label>
                <TextArea type="text" id="custom" name="custom"/>
              </div>

            </SocialMediaInputs>

          </GridChild>

          {/* <Recaptcha
           sitekey="6LcznAcbAAAAAPYLPH18w_PZLsdnYlvW4hci8i0z"
           render="explicit"
           onloadCallback={callback}
          /> */}

          <Submit type="submit">Submit</Submit>

        </Form>
      </StyledFullHeightSection>


    </Layout>
  );
};

export default Index;
